import { Box, Button, Link as MuiLink, Typography } from '@mui/material'
import Head from 'next/head'
import { useRouter } from 'next/router'

import theme from '../styles/theme'

export default function HomePage() {
  const router = useRouter()
  return (
    <>
      <Head>
        <title>Co-Man Search | Slater</title>
        <meta name="description" content="Slater Frontend" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Box
        height="100vh"
        width="100vw"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          flex="1"
        >
          <Typography variant="h2" pb={4}>
            🕵️ Welcome to Slater Search
          </Typography>
          <Button
            component={MuiLink}
            href="/api/auth/login?returnTo=/search"
            variant="contained"
            color="primary"
            width="200px"
            sx={{
              textTransform: 'none',
            }}
          >
            Sign In
          </Button>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            pt={3}
            color={theme.palette.text.tertiary}
          >
            <Typography variant="body2">Powered by</Typography>
            <img src="/partnerslate-logo.png" width={24} height={24} alt="PartnerSlate Logo" />
            <Typography variant="body2">PartnerSlate</Typography>
          </Box>

          <Box height={200}></Box>
        </Box>
        {/* <Box component="footer">
          <Typography pt={4} pb={4} textAlign="center" variant="body2">
            &copy; {new Date().getFullYear()} PartnerSlate. All Rights Reserved.
          </Typography>
        </Box> */}
      </Box>
    </>
  )
}